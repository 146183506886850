import React, { useState } from 'react';

import { motion } from 'framer-motion';
import HTMLReactParser from 'html-react-parser';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

import DynaContent from 'components/dcontent';
import ContactForm from 'components/contact-form';
import Popup from 'components/popup';

import { grey } from 'theme/colors';
import { pageAnims } from 'theme/animations';

const TemplateEnchimentos = (props) => {
  //PROPS
  const {
    lang,
    data: {
      page: { dcontent },
    },
  } = props;
  //PROPS

  const [extraInfo, setExtraInfo] = useState();

  return (
    <>
      <Popup
        show={!!extraInfo}
        toggle={() => {
          setExtraInfo(null);
        }}
      >
        <h3>{extraInfo?.nome}</h3>
        <div className="popup-image">
          <GatsbyImage
            image={
              extraInfo?.thumbnailImage?.localFile?.childImageSharp
                ?.gatsbyImageData
            }
            objectFit="contain"
            alt={extraInfo?.thumbnailImage?.altText}
          />
        </div>
        {extraInfo?.thumbnailDescription &&
          HTMLReactParser(extraInfo.thumbnailDescription)}
      </Popup>
      <motion.div
        style={{
          background: grey.default,
          minHeight: '100vh',
          marginBottom: '86px',
        }}
        variants={pageAnims}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <DynaContent rows={dcontent.dcontent} setExtraInfo={setExtraInfo} />
      </motion.div>
      <ContactForm lang={lang} />
    </>
  );
};

export const query = graphql`
  query Fabrica($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      id
      dcontent {
        dcontent {
          ... on WpPage_Dcontent_Dcontent_Banner {
            banner {
              imageFirst
              text
              title
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
          ... on WpPage_Dcontent_Dcontent_Relations {
            relations {
              ... on WpTecido {
                id
                noticias {
                  nome
                  thumbnailDescription
                  thumbnailText
                  thumbnailImage {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
                      }
                    }
                  }
                }
              }
              ... on WpEnchimento {
                id
                projects {
                  nome
                  thumbnailDescription
                  thumbnailText
                  thumbnailImage {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
                      }
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Dcontent_Dcontent_Thumbnails {
            thumbnail {
              description
              fieldGroupName
              title
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
            }
            fieldGroupName
          }
          ... on WpPage_Dcontent_Dcontent_BannerImages {
            bannerImages {
              image {
                altText
                width
                height
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
              image2 {
                altText
                width
                height
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
          ... on WpPage_Dcontent_Dcontent_Video {
            videoBanner {
              mediaItemUrl
            }
          }
          ... on WpPage_Dcontent_Dcontent_Textblock {
            text
          }
        }
      }
    }
  }
`;

export default TemplateEnchimentos;
